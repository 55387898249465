import React, { useState, useEffect } from 'react';
import './Dashboard.css';

function Dashboard() {
  const [activeTab, setActiveTab] = useState('home');
  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [botRunning, setBotRunning] = useState(false); // Track bot's status

  // Fetching user data
  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await fetch('/api/user');
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
          setSettings(data.user.settings);
          await checkBotStatus(data.user.id);  // Check bot status as soon as user data is loaded
        } else {
          console.error('Failed to load user data:', response.statusText);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    }

    fetchUserData();
  }, []);

  // Check the bot status (whether it's running or not)
  const checkBotStatus = async (userId) => {
    try {
      const response = await fetch(`/api/bot/status/${userId}`);
      if (response.ok) {
        const data = await response.json();
        setBotRunning(data.isRunning); // Set bot status
      } else {
        console.error('Failed to check bot status:', response.statusText);
      }
    } catch (error) {
      console.error('Error checking bot status:', error);
    } finally {
      setLoading(false);  // Set loading to false once bot status is checked
    }
  };

  const handleTabChange = (tab) => setActiveTab(tab);

  // Enable/Disable bot feature
  const toggleBotEnabled = async () => {
    const updatedBotEnabled = !settings.botEnabled;
    try {
      const response = await fetch('/api/settings/bot-enabled', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ botEnabled: updatedBotEnabled }),
      });

      const data = await response.json();
      if (response.ok) {
        setSettings((prev) => ({
          ...prev,
          botEnabled: updatedBotEnabled,
        }));
      } else {
        alert('Failed to update bot status.');
      }
    } catch (error) {
      alert('An unexpected error occurred while updating bot status.');
    }
  };

  // Save settings
  const saveSettings = async () => {
    if (!settings) return;
    try {
      const response = await fetch('/api/settings', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(settings),
      });

      const data = await response.json();
      if (response.ok) {
        setSettings(data.settings);
        alert('Settings updated successfully!');
      } else {
        alert(`Error saving settings: ${data.error}`);
      }
    } catch (error) {
      alert('An unexpected error occurred while saving settings.');
    }
  };

  // Start the bot
  const startBot = async () => {
    try {
      const response = await fetch('/api/bot/start', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Unknown error occurred');
      }

      setBotRunning(true); // Mark the bot as running
    } catch (error) {
      alert('Failed to start bot.');
    }
  };

  // Stop the bot
  const stopBot = async () => {
    try {
      if (!user || !user.id) {
        alert('User ID is not available.');
        return;
      }

      const response = await fetch('/api/bot/stop', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: user.id }),
      });

      const data = await response.json();
      if (response.ok) {
        setBotRunning(false); // Mark the bot as stopped
        alert('Bot stopped successfully!');
      } else {
        alert(`Error stopping bot: ${data.error}`);
      }
    } catch (error) {
      alert('An unexpected error occurred while stopping the bot.');
    }
  };

  if (loading) return <p>Loading...</p>;

  if (!settings) return <p>Error loading settings.</p>;

  return (
    <div className="dashboard-container">
      <div className="sidebar">
        <h2>Dashboard</h2>
        <ul>
          <li
            className={activeTab === 'home' ? 'active' : ''}
            onClick={() => handleTabChange('home')}
          >
            Home
          </li>
          <li
            className={activeTab === 'settings' ? 'active' : ''}
            onClick={() => handleTabChange('settings')}
          >
            Bot Settings
          </li>
        </ul>
      </div>
      <div className="content">
        {activeTab === 'home' && (
          <div className="home-tab">
            <h3>Welcome, {user ? user.displayName : 'User'}</h3>
            <p>Status: {settings.botEnabled ? 'Enabled' : 'Disabled'}</p>

            {/* Toggle bot status */}
            <button
              onClick={toggleBotEnabled}
              disabled={botRunning} // Disable if bot is running
            >
              {settings.botEnabled ? 'Disable Bot' : 'Enable Bot'}
            </button>

            {/* Start Bot Button */}
            <button
              onClick={startBot}
              disabled={botRunning} // Disable if bot is already running
            >
              Start Bot
            </button>

            {/* Stop Bot Button */}
            <button
              onClick={stopBot}
              disabled={!botRunning} // Disable if bot is not running
            >
              Stop Bot
            </button>
          </div>
        )}

        {activeTab === 'settings' && (
          <div className="settings-tab">
            <h3>Bot Personality</h3>
            <h4>Provide the personality prompt for your bot here, for example:</h4>
            <textarea
              value="You are a Medieval Tavern Keeper Stream Assistant Bot"
              disabled
              rows="2"
              style={{ fontStyle: 'italic', color: '#d4af37', backgroundColor: '#2a2a2a' }}
            />
            <textarea
              value={settings.personalityPrompt || ''}
              onChange={(e) =>
                setSettings((prev) => ({
                  ...prev,
                  personalityPrompt: e.target.value,
                }))
              }
              rows="4"
              placeholder="Enter bot's personality prompt"
            />
            <h3>Event Settings</h3>
            <label>
              <input
                type="checkbox"
                checked={settings.respondToSubscriptions || false}
                onChange={() =>
                  setSettings((prev) => ({
                    ...prev,
                    respondToSubscriptions: !prev.respondToSubscriptions,
                  }))
                }
              />
              Respond to Subscriptions
            </label>
            <label>
              <input
                type="checkbox"
                checked={settings.respondToRaids || false}
                onChange={() =>
                  setSettings((prev) => ({
                    ...prev,
                    respondToRaids: !prev.respondToRaids,
                  }))
                }
              />
              Respond to Raids
            </label>
            <label>
              <input
                type="checkbox"
                checked={settings.respondToAds || false}
                onChange={() =>
                  setSettings((prev) => ({
                    ...prev,
                    respondToAds: !prev.respondToAds,
                  }))
                }
              />
              Respond to Ads
            </label>
            <label>
              <input
                type="checkbox"
                checked={settings.respondToShoutout || false}
                onChange={() =>
                  setSettings((prev) => ({
                    ...prev,
                    respondToShoutout: !prev.respondToShoutout,
                  }))
                }
              />
              Respond to Shoutouts (!so [Username])
            </label>
            <label>
              <input
                type="checkbox"
                checked={settings.respondToLurk || false}
                onChange={() =>
                  setSettings((prev) => ({
                    ...prev,
                    respondToLurk: !prev.respondToLurk,
                  }))
                }
              />
              Respond to Lurk Commands (!lurk)
            </label>
            <button onClick={saveSettings}>Save Settings</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
