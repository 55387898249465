import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';

function LandingPage() {
  return (
    <div className="landing-page">
      <div className="landing-content">
        <h1>Welcome to the Traveller's Tavern Bot Dashboard</h1>
        <a href="/auth/twitch" className="twitch-button">Sign in with Twitch</a>
        <p class="description">
          The Traveller's Tavern Bot is a customizable Twitch bot that provides
          stream management tools, viewer engagement features, and advanced
          customization options to elevate your streaming experience.
        </p>
		<p class="terms">
		  By using the Traveller's Tavern Bot, you agree to follow Twitch's Community Guidelines and Terms of Service. The Bot is designed to enhance chat engagement and moderation. It processes user data solely for functionality and does not store personal information unnecessarily. Use is at your own risk, and we are not liable for interruptions or errors.
        </p>
		<p class="terms">
		 For inquiries, contact me on Discord @NotDelbert.
		</p>
	  </div>
    </div>
  );
}

export default LandingPage;
